import {useState, useEffect, useMemo, useRef, createContext, useContext, useCallback} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  getAppDownloadLinks,
  getTicketLinks, getUploadTypes,
  checkAlreadyUploaded,
  uploadTicket,
  removeTicket,
  submitTickets,
} from './api.js';

import logo from './uwork-logo-white.png';
import './App.css';

// create context to pass id, triggerMessageModal, triggerConfirmationModal, and triggerCroppingModal
const AppContext = createContext(null);

function logError(error) {
  if (error.response) {
    console.log('ERROR:\n', error.response);
  } else {
    console.log('ERROR:\n', error);
  }
}

function MessageModal({modalDetails = {}}) {
  return (
    <Modal
      show={modalDetails.show}
    >
      <h4>{modalDetails.title}</h4>
      <p>{modalDetails.message}</p>
      <div className="footer-ctn">
        <button
          className="btn-yellow filled"
          onClick={() => modalDetails.onToggle()}
        >
          OK
        </button>
      </div>
    </Modal>
  )
}

function ConfirmationModal({modalDetails = {}}) {
  return (
    <Modal
      className="confirmation-modal"
      show={modalDetails.show}
    >
      <h4>{modalDetails.title}</h4>
      <p>{modalDetails.message}</p>
      {modalDetails.list && modalDetails.list.length > 0 ?
        <ul>
          {modalDetails.list.map((li, i) =>
            <li key={i}>{li}</li>
          )}
        </ul>
        :
        null
      }
      <div className="footer-ctn">
        <button
          className="btn-yellow"
          onClick={() => modalDetails.leftButtonClick()}
        >
          {modalDetails.leftButtonText}
        </button>
        <button
          className="btn-yellow filled"
          onClick={() => modalDetails.rightButtonClick()}
        >
          {modalDetails.rightButtonText}
        </button>
      </div>
    </Modal>
  )
}

function CroppingModal({modalDetails = {}}) {
  const imgRef = useRef();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [rotate, setRotate] = useState(0);

  // This is to demonstrate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(mediaWidth = 0, mediaHeight = 0) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        16 / 9,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  function onImageLoad(event) {
    // flush
    setRotate(0);

    const {width, height} = event.currentTarget;
    setCrop(centerAspectCrop(width, height));
  }

  function rotateClockwise(by = 0) {
    let newRotate = rotate + by;
    if (newRotate > 180) {
      newRotate = -90;
    } else if (newRotate < -180) {
      newRotate = 90;
    }

    setRotate(newRotate);
  }

  function onCropButtonClick() {
    if (modalDetails.triggerLoading) {
      modalDetails.triggerLoading();
    }

    const ToRadians = Math.PI / 180;
    const maximumCanvasArea = 16777216; // max for safari canvas 16Mb
    const image = imgRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    let pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1;

    const canvas = document.createElement('canvas');
    let width = Math.floor(completedCrop.width * scaleX * pixelRatio);
    let height = Math.floor(completedCrop.height * scaleY * pixelRatio);

    // adjust pixelRatio to scale canvas to iOS safari max canvas size
    if (width && height && width * height > maximumCanvasArea) {
      pixelRatio = Math.sqrt(maximumCanvasArea / (width * height)) * pixelRatio;
      width = Math.floor(completedCrop.width * scaleX * pixelRatio);
      height = Math.floor(completedCrop.height * scaleY * pixelRatio);
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = completedCrop.x * scaleX;
      const cropY = completedCrop.y * scaleY;

      const rotateRads = rotate * ToRadians;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      // 4) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 3) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 2) Rotate around the origin
      ctx.rotate(rotateRads);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
      );

      canvas.toBlob((blob) => {
        modalDetails.cropButtonClick(blob);
      }, 'image/jpeg', 0.8);
    } else {
      console.log('Error getting canvas context');
      modalDetails.cropButtonClick(null);
    }
  }

  return (
    <Modal
      className="cropping-modal"
      show={modalDetails.show}
    >
      <div className="header-ctn">
        <button
          className="x-btn"
          onClick={() => modalDetails.xButtonClick()}
        >
          ❌
        </button>
      </div>
      <h4>{modalDetails.typeName} Certificate</h4>
      <p>Drag the highlighted square to crop and the arrows to rotate the image.</p>
      <div className="crop-ctn">
        <button
          className="btn-yellow"
          onClick={() => rotateClockwise(-90)}
        >
          ↺
        </button>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={modalDetails.imgSrc}
            style={{transform: `rotate(${rotate}deg)`}}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        <button
          className="btn-yellow"
          onClick={() => rotateClockwise(90)}
        >
          ↻
        </button>
      </div>
      <div className="footer-ctn">
        <button
          className="btn-yellow"
          onClick={onCropButtonClick}
        >
          CROP
        </button>
      </div>
    </Modal>
  )
}

function SaveTicket(id = '', groupId = '', typeId = 0, fileName = '', blob = null, cb = null) {
  if (blob) {
    const key = 'file';
    const data = new FormData();
    data.append(key, blob);
    data.append(`${key}_name`, fileName);
    data.append('type_id', typeId.toString());
    if (groupId) {
      data.append('group_id', groupId);
    }

    uploadTicket(id, data).then((response) => {
      console.log('uploaded ticket:\n', response.data);
      if (response.data.data && response.data.data[key] && cb) {
        cb(response.data.data[key]);
      }
    }).catch((error) => {
      if (cb) {
        cb({err: error});
      } else {
        logError(error);
      }
    });
  } else {
    const error = 'missing blob';
    if (cb) {
      cb({err: error});
    } else {
      logError(error);
    }
  }
}

function DeleteTicket(id = '', path = '', cb = null) {
  if (path) {
    removeTicket(id, path).catch(logError).finally(() => cb ? cb() : null);
  }
}

function UploadButton({uploadDetails = {}, setUploadDetails = null, isTicket1 = false}) {
  const app = useContext(AppContext);
  const inputFileRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // max file restrictions in bytes
  const maximumFileSize = 10485760; // 10Mb

  const onClickUploadTicket = () => {
    if (!uploadDetails.type_id) {
      app.triggerMessageModal(
        true,
        `Can't Upload`,
        'You must select the type of ticket you have before you can upload.',
      );
    } else {
      inputFileRef.current.click();
    }
  };

  const resetFile = () => inputFileRef.current.value = '';

  const fileChange = (e) => {
    console.log('upload files:\n', e.target.files);
    if (e.target.files.length === 1 && (e.target.files[0].size || 0) > 0) {
      const file = e.target.files[0];

      // check max file size
      if (file.size > maximumFileSize) {
        const i = Math.floor(Math.log(maximumFileSize) / Math.log(1024));
        const size = `${(maximumFileSize / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;

        app.triggerMessageModal(
          true,
          'Upload Error',
          `File too big, must be less than ${size}.`,
        );

        // reset input file value
        resetFile();

        return;
      }

      const updateUploadDetails = (blob) => {
        return function (resp = {group_id: '', path: '', err: ''}) {
          if (resp.err) {
            console.log("Error saving ticket: ", resp.err);
            app.triggerMessageModal(
              true,
              'Upload Error',
              'An error occurred trying to upload the certificate, try again later.',
            );

            // reset input file value
            resetFile();
          } else {
            const newUploadDetails = {...uploadDetails, groupId: resp.group_id};
            newUploadDetails[isTicket1 ? 'ticket_1' : 'ticket_2'] = {
              name: file.name,
              path: resp.path,
              blobURL: URL.createObjectURL(blob),
            };
            setUploadDetails(newUploadDetails);
          }
          setLoading(false);
        };
      };

      if (file.type.startsWith('image')) {
        app.triggerCroppingModal(
          true,
          uploadDetails.type_name,
          URL.createObjectURL(file),
          resetFile,
          (blob) => {
            SaveTicket(app.id, uploadDetails.groupId, uploadDetails.type_id, file.name, blob, updateUploadDetails(blob));
          },
          () => setLoading(true),
        );
      } else {
        setLoading(true);
        SaveTicket(app.id, uploadDetails.groupId, uploadDetails.type_id, file.name, file, updateUploadDetails(file));
      }
    }
  };

  // TODO progress bar
  return (
    <button
      className={`btn-yellow upload-btn${!uploadDetails.type_id ? ' disabled' : ''}${loading ? ' loading' : ''}`}
      onClick={onClickUploadTicket}
      hidden={uploadDetails[isTicket1 ? 'ticket_1' : 'ticket_2'].name}
      disabled={loading}
    >
      {loading ?
        <div className="spinner loading"/>
        :
        null
      }
      <input
        type="file"
        ref={inputFileRef}
        onChange={fileChange}
        accept=".png,.jpg,.jpeg,.docx,.pdf"
      />
      {/* "UPLOAD" or "↑" in App.css */}
    </button>
  )
}

function XButton({uploadDetails = {}, setUploadDetails = null, isTicket1 = false}) {
  const app = useContext(AppContext);

  let fn = null;
  if (isTicket1) {
    if (uploadDetails.ticket_1.name) {
      // delete ticket
      fn = () => {
        app.triggerConfirmationModal(
          true,
          'Remove Upload',
          'Are you sure you want to remove the upload for the following ticket?',
          [`${uploadDetails.type_name} - ${uploadDetails.ticket_1.name}`],
          'REMOVE UPLOAD',
          `DON'T REMOVE`,
          () => {
            DeleteTicket(app.id, uploadDetails.ticket_1.path, () => {
              console.log('update server removed ticket 1');
              URL.revokeObjectURL(uploadDetails.ticket_1.blobURL);
              setUploadDetails({
                ...uploadDetails,
                groupId: uploadDetails.ticket_2.name ? uploadDetails.groupId : null,
                ticket_1: {},
              });
            });
          },
          null,
        );
      };
    } else {
      // remove certificate
      if (uploadDetails.ticket_2.name) {
        fn = () => {
          app.triggerConfirmationModal(
            true,
            'Remove Upload',
            'Are you sure you want to remove the upload for the following ticket?',
            [`${uploadDetails.type_name} - 2nd Side - ${uploadDetails.ticket_2.name}`],
            'REMOVE UPLOAD',
            `DON'T REMOVE`,
            () => {
              DeleteTicket(app.id, uploadDetails.ticket_2.path, () => {
                console.log('update server removed ticket 2');
                URL.revokeObjectURL(uploadDetails.ticket_2.blobURL);
                setUploadDetails();
              });
            },
            null,
          );
        };
      } else {
        fn = () => setUploadDetails();
      }
    }
  } else {
    if (uploadDetails.ticket_2.name) {
      // delete ticket
      fn = () => {
        app.triggerConfirmationModal(
          true,
          'Remove Upload',
          'Are you sure you want to remove the upload for the following ticket?',
          [`${uploadDetails.type_name} - 2nd Side - ${uploadDetails.ticket_2.name}`],
          'REMOVE UPLOAD',
          `DON'T REMOVE`,
          () => {
            DeleteTicket(app.id, uploadDetails.ticket_2.path, () => {
              console.log('update server removed ticket 2');
              URL.revokeObjectURL(uploadDetails.ticket_2.blobURL);
              setUploadDetails({
                ...uploadDetails,
                groupId: uploadDetails.ticket_1.name ? uploadDetails.groupId : null,
                ticket_2: {},
              });
            });
          },
          null,
        );
      };
    } else {
      // remove option to add second side
      fn = () => setUploadDetails({...uploadDetails, add_side_2: false});
    }
  }

  return (
    <button
      className="btn-red"
      onClick={() => fn()}
    >
      ❌
    </button>
  )
}

function DefaultUploadRow({uploadType = {}, ticketLink = '', uploadDetails = {}, setUploadDetails = null, required = false}) {
  return (
    <div className="upload-row" key={uploadType.type_id}>
      <div className="left-column">
        <h3>{uploadType.type_name}{required && '*'}</h3>
        {!uploadDetails.ticket_1.name && ticketLink ?
          <div>
            Don't have your {uploadType.type_name}?
            <a
              className="ticket-link"
              href={ticketLink}
              target="_blank"
              rel="noreferrer"
            ><b>Take the free course</b>
            </a>
          </div>
          :
          <a
            className="ticket-download-link"
            href={uploadDetails.ticket_1.blobURL}
            target="_blank"
            rel="noreferrer"
          >
            {uploadDetails.ticket_1.name}
          </a>
        }
      </div>
      {!uploadDetails.ticket_1.name ?
        <UploadButton
          uploadDetails={{...uploadDetails, ...uploadType}}
          setUploadDetails={setUploadDetails}
          isTicket1
        />
        :
        <XButton
          uploadDetails={{...uploadDetails, ...uploadType}}
          setUploadDetails={setUploadDetails}
          isTicket1
        />
      }
    </div>
  );
}

function OptionalUploadRow({uploadTypes = [], uploadDetails = {}, setUploadDetails = null}) {
  const selection = (uploadDetails.type_id && uploadTypes.filter((option) => option.type_id === uploadDetails.type_id)[0].type_name) || 'Select Certificate';

  return (
    <div className="optional-upload-row">
      <div className="side-1">
        <div className="left-column">
          {!uploadDetails.ticket_1.name && !uploadDetails.ticket_2.name ?
            <DropdownButton className="upload-types" title={selection}>
              {uploadTypes.map((option, index) => (
                <Dropdown.Item
                  as="button"
                  key={index}
                  onClick={() => setUploadDetails({type_id: option.type_id, type_name: option.type_name})}
                  active={uploadDetails.type_id === option.type_id}
                >
                  {option.type_name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            :
            <h3>{uploadDetails.type_name}</h3>
          }
          {uploadDetails.ticket_1.name ?
            <a
              className="ticket-download-link"
              href={uploadDetails.ticket_1.blobURL}
              target="_blank"
              rel="noreferrer"
            >
              {uploadDetails.ticket_1.name}
            </a>
            :
            null
          }
          {uploadDetails.type_id && !uploadDetails.add_side_2 ?
            <button
              className="side-2-btn"
              onClick={() => setUploadDetails({add_side_2: true})}
            >
              Add 2nd side for {uploadDetails.type_name}
            </button>
            :
            null
          }
        </div>
        <div className="right-column">
          <UploadButton
            uploadDetails={uploadDetails}
            setUploadDetails={setUploadDetails}
            isTicket1
          />
          <XButton
            uploadDetails={uploadDetails}
            setUploadDetails={setUploadDetails}
            isTicket1
          />
        </div>
      </div>
      {uploadDetails.add_side_2 ?
        <div className="side-2">
          <div className="left-column">
            <h4>{uploadDetails.type_name} - 2nd Side</h4>
            {uploadDetails.ticket_2.name ?
              <a
                className="ticket-download-link"
                href={uploadDetails.ticket_2.blobURL}
                target="_blank"
                rel="noreferrer"
              >
                {uploadDetails.ticket_2.name}
              </a>
              :
              null
            }
          </div>
          <div className="right-column">
            <UploadButton
              uploadDetails={uploadDetails}
              setUploadDetails={setUploadDetails}
            />
            <XButton
              uploadDetails={uploadDetails}
              setUploadDetails={setUploadDetails}
            />
          </div>
        </div>
        :
        null
      }
    </div>
  )
}

function App() {
  const defaultUploadDetails = (required = false) => {
    return {type_id: 0, type_name: '', ticket_1: {}, ticket_2: {}, add_side_2: false, required};
  };

  // default message modal
  const defaultMessageModalDetails = useCallback(() => {
    return {
      show: false,
      title: '',
      message: '',
      onToggle: () => {
      },
    };
  }, []);

  // default confirmation modal
  const defaultConfirmationModalDetails = useCallback(() => {
    return {
      show: false,
      title: '',
      message: '',
      list: [],
      leftButtonText: 'No',
      rightButtonText: 'Yes',
      leftButtonClick: () => {
      },
      rightButtonClick: () => {
      },
    }
  }, []);

  // default cropping modal
  const defaultCroppingModalDetails = useCallback(() => {
    return {
      show: false,
      typeName: '',
      imgSrc: '',
      xButtonClick: () => {
      },
      cropButtonClick: () => {
      },
      triggerLoading: () => {
      },
    }
  }, []);

  const [uploadTypeWHMIS, setUploadTypeWHMIS] = useState({type_name: 'WHMIS'});
  const [uploadType4StepsOfHealthAndSafety, setUploadType4StepsOfHealthAndSafety] = useState({type_name: '4 Steps of Health and Safety'});
  const [uploadTypeResume, setUploadTypeResume] = useState({type_name: 'Resume'});
  const [uploadTypes, setUploadTypes] = useState([{type_id: 0, type_name: ''}]);
  const [ticketLinks, setTicketLinks] = useState({link_4_step: '', link_whmis: ''});
  const [appDownloadLinks, setAppDownloadLinks] = useState({ios: '', android: ''});
  const [alreadyUploaded, setAlreadyUploaded] = useState(true);
  const [name, setName] = useState('');
  const [defaultUploads, setDefaultUploads] = useState([defaultUploadDetails(true), defaultUploadDetails(true), defaultUploadDetails()]);
  const [optionalUploads, setOptionalUploads] = useState([]);
  const [modals, setModals] = useState({
    messageModal: defaultMessageModalDetails(),
    confirmationModal: defaultConfirmationModalDetails(),
    croppingModal: defaultCroppingModalDetails(),
  });
  const [spinner, setSpinner] = useState(true);

  // get id should be last piece of url
  const id = useMemo(() => window.location.pathname.substring(1), []);

  // set up triggerMessageModal
  const triggerMessageModal = useCallback((show = false, title = '', message = '', onToggle = null) => {
    const modalDetails = defaultMessageModalDetails();
    modalDetails.show = show;
    modalDetails.title = title;
    modalDetails.message = message;

    const fns = [() => setModals(modals => ({...modals, messageModal: defaultMessageModalDetails()}))];
    if (onToggle) {
      fns.unshift(onToggle);
    }
    modalDetails.onToggle = () => fns.forEach((fn) => fn());

    setModals(modals => ({...modals, messageModal: modalDetails}));
  }, [defaultMessageModalDetails]);

  // set up triggerConfirmationModal
  const triggerConfirmationModal = useCallback((
    show = false,
    title = '',
    message = '',
    list = [],
    leftButtonText = '',
    rightButtonText = '',
    leftButtonClick = null,
    rightButtonClick = null,
  ) => {
    const modalDetails = defaultConfirmationModalDetails();
    modalDetails.show = show;
    modalDetails.title = title;
    modalDetails.message = message;
    modalDetails.list = list || [];

    if (leftButtonText) {
      modalDetails.leftButtonText = leftButtonText;
    }

    if (rightButtonText) {
      modalDetails.rightButtonText = rightButtonText;
    }

    const lfns = [() => setModals(modals => ({...modals, confirmationModal: defaultConfirmationModalDetails()}))];
    if (leftButtonClick) {
      lfns.unshift(leftButtonClick);
    }
    modalDetails.leftButtonClick = () => lfns.forEach((fn) => fn());

    const rfns = [() => setModals(modals => ({...modals, confirmationModal: defaultConfirmationModalDetails()}))];
    if (rightButtonClick) {
      rfns.unshift(rightButtonClick);
    }
    modalDetails.rightButtonClick = () => rfns.forEach((fn) => fn());

    setModals(modals => ({...modals, confirmationModal: modalDetails}));
  }, [defaultConfirmationModalDetails]);

  // set up triggerCroppingModal
  const triggerCroppingModal = useCallback((
    show = false,
    typeName = '',
    imgSrc = '',
    xButtonClick = null,
    cropButtonClick = null,
    triggerLoading = null,
  ) => {
    const modalDetails = defaultCroppingModalDetails();
    modalDetails.show = show;
    modalDetails.typeName = typeName;
    modalDetails.imgSrc = imgSrc;

    const xFns = [() => setModals(modals => ({...modals, croppingModal: defaultCroppingModalDetails()}))];
    if (xButtonClick) {
      xFns.unshift(xButtonClick);
    }
    modalDetails.xButtonClick = () => xFns.forEach((fn) => fn());

    const cropFns = [() => setModals(modals => ({...modals, croppingModal: defaultCroppingModalDetails()}))];
    if (cropButtonClick) {
      cropFns.unshift(cropButtonClick);
    }
    modalDetails.cropButtonClick = (...a) => cropFns.forEach((fn) => fn(...a));

    if (triggerLoading) {
      modalDetails.triggerLoading = triggerLoading;
    }

    setModals(modals => ({...modals, croppingModal: modalDetails}));
  }, [defaultCroppingModalDetails]);

  // default api calls
  useEffect(() => {
    // get app download links
    getAppDownloadLinks().then((response) => {
      console.log('app download links:\n', response.data);
      setAppDownloadLinks(response.data.data);
    }).catch(logError);

    // get ticket links
    getTicketLinks().then((response) => {
      console.log('ticket links:\n', response.data);
      setTicketLinks(response.data.data);
    }).catch(logError);

    // get static upload types
    getUploadTypes().then((response) => {
      console.log('static upload types:\n', response.data);
      // extract and store static upload types and generate optional upload types
      const data = response.data.data.filter((ut) => {
        switch (ut.type_name) {
          case uploadTypeWHMIS.type_name:
            setUploadTypeWHMIS(ut);
            break;
          case uploadType4StepsOfHealthAndSafety.type_name:
            setUploadType4StepsOfHealthAndSafety(ut);
            break;
          case uploadTypeResume.type_name:
            setUploadTypeResume(ut);
            break;
          case 'etc':
            break;
          default:
            return true;
        }

        return false;
      });

      setUploadTypes(data);
    }).catch(logError);

    // check if upload already done
    if (id) {
      checkAlreadyUploaded(id).then((response) => {
        console.log('already uploaded ticket:\n', response.data);
        const data = response.data.data;
        setAlreadyUploaded(data.uploaded);
        setName(data.name);
      }).catch(logError).finally(() => setSpinner(false));
    } else {
      setSpinner(false);
    }
  }, [id, uploadTypeWHMIS.type_name, uploadType4StepsOfHealthAndSafety.type_name, uploadTypeResume.type_name]);

  const getTicketLink = (typeName) => {
    switch (typeName) {
      case uploadTypeWHMIS.type_name:
        return ticketLinks.link_whmis;
      case uploadType4StepsOfHealthAndSafety.type_name:
        return ticketLinks.link_4_step;
      default:
        return '';
    }
  };

  const getSetUploadDetails = (i, uploads, setUploads) => {
    return function (uploadDetails) {
      if (!uploadDetails) {
        // remove
        setUploads(uploads.filter((_, j) => i !== j) || []);
      } else {
        // add
        setUploads(uploads.map((u, j) => {
          if (i === j) {
            return {...u, ...uploadDetails};
          } else {
            return u;
          }
        }));
      }
    }
  };

  const disableAddCertificate = (() => {
    for (const u of defaultUploads) {
      if (u.required && (!u.ticket_1 || typeof u.ticket_1.name === 'undefined' || u.ticket_1.name === '')) {
        return true;
      }
    }

    for (const u of optionalUploads) {
      if (!u.ticket_1 || typeof u.ticket_1.name === 'undefined' || u.ticket_1.name === '') {
        return true;
      }
    }

    return false;
  })();

  const disableSubmit = (() => {
    for (const u of defaultUploads) {
      if (u.required && (!u.ticket_1 || typeof u.ticket_1.name === 'undefined' || u.ticket_1.name === '')) {
        return true;
      }
    }

    return false;
  })();

  const onClickAddCertificate = () => {
    if (disableAddCertificate) {
      triggerMessageModal(
        true,
        `Can't Add Certificate`,
        'You must upload the required tickets before adding a new certificate.'
      );
    } else {
      setOptionalUploads([...optionalUploads, defaultUploadDetails()]);
    }
  };

  const onClickSubmit = () => {
    if (disableSubmit) {
      triggerMessageModal(
        true,
        `Can't Submit`,
        'You must upload the required tickets before submitting tickets or resume.',
      );
    } else {
      // check for not submitted certificates
      const list = optionalUploads.reduce((l, u) => {
        if (u.type_id) {
          if (!u.ticket_1 || typeof u.ticket_1.name === 'undefined' || u.ticket_1.name === '') {
            l.push(`${u.type_name} - Not Submitted`);
          }
          if (u.add_side_2 && (!u.ticket_2 || typeof u.ticket_2.name === 'undefined' || u.ticket_2.name === '')) {
            l.push(`${u.type_name} - 2nd Side - Not Submitted`);
          }
        }
        return l;
      }, []) || [];

      if (list.length > 0) {
        triggerConfirmationModal(
          true,
          'Confirm Submission',
          `You haven't uploaded the following tickets yet, are you sure you want to submit your tickets?`,
          list,
          'KEEP UPLOADING',
          'SUBMIT ANYWAYS',
          null,
          () => {
            submitTickets(id).then((response) => {
              console.log('submitted tickets:\n', response.data);
            }).catch(logError).finally(() => setAlreadyUploaded(true));
          },
        )
      } else {
        submitTickets(id).then((response) => {
          console.log('submitted tickets:\n', response.data);
        }).catch(logError).finally(() => setAlreadyUploaded(true));
      }
    }
  };

  return (
    <div className="App">
      {spinner ?
        <div className="spinner"/>
        :
        <AppContext.Provider value={{id, triggerMessageModal, triggerConfirmationModal, triggerCroppingModal}}>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <h1>
              {alreadyUploaded ?
                'Ticket Attachment Successful!'
                :
                'Attach Certificates to your Profile'
              }
            </h1>
          </header>

          {alreadyUploaded ?
            /* Download App Container */
            <div className="done-ctn">
              <h4>You've successfully attached your tickets!</h4>
              <p>
                The next step is to download the app. You'll receive a link to the app in your text messages.
                <br/>
                Make sure you add the same phone number to your profile that you used for your application to link your
                application to your profile.
              </p>
              <p>
                If you don't get the text message, you can download the app here:
              </p>
              <a className="btn-yellow filled" href={appDownloadLinks.ios}>
                iOS
              </a>
              <a className="btn-yellow filled" href={appDownloadLinks.android}>
                Android
              </a>
            </div>
            :
            /* Upload Certificate Container */
            <>
              <p>
                Hi{name ? ` ${name}` : null}, thanks for applying to UWork! Upload your training and certificates
                below to complete your application.
              </p>
              <div className="uploads-ctn">
                {/* Default Upload Rows */}
                <DefaultUploadRow
                  uploadType={uploadTypeWHMIS}
                  ticketLink={getTicketLink(uploadTypeWHMIS.type_name)}
                  uploadDetails={defaultUploads[0]}
                  setUploadDetails={getSetUploadDetails(0, defaultUploads, setDefaultUploads)}
                  required={defaultUploads[0].required}
                />
                <DefaultUploadRow
                  uploadType={uploadType4StepsOfHealthAndSafety}
                  ticketLink={getTicketLink(uploadType4StepsOfHealthAndSafety.type_name)}
                  uploadDetails={defaultUploads[1]}
                  setUploadDetails={getSetUploadDetails(1, defaultUploads, setDefaultUploads)}
                  required={defaultUploads[1].required}
                />
                <DefaultUploadRow
                  uploadType={uploadTypeResume}
                  uploadDetails={defaultUploads[2]}
                  setUploadDetails={getSetUploadDetails(2, defaultUploads, setDefaultUploads)}
                  required={defaultUploads[2].required}
                />

                {/* Optional Upload Rows */}
                {optionalUploads ? optionalUploads.map((u, i) =>
                    <OptionalUploadRow
                      key={i}
                      uploadTypes={uploadTypes}
                      uploadDetails={u}
                      setUploadDetails={getSetUploadDetails(i, optionalUploads, setOptionalUploads)}
                    />
                  )
                  :
                  null
                }

                {/* Add Certificate Row */}
                <div className="upload-row">
                  <div className="left-column">
                    <h3>Add Another Safety Certificate</h3>
                    <div>E.g. Working at Heights, Forklift, Smart Serve, etc.</div>
                  </div>
                  <button
                    className={`btn-yellow add-certificate-btn${disableAddCertificate ? ' disabled' : ''}`}
                    onClick={onClickAddCertificate}
                  >
                    {/* "ADD CERTIFICATE" and "＋" in App.css */}
                  </button>
                </div>

                {/* Submit Button Row */}
                <div className="upload-row submit">
                  <button
                    className={`btn-yellow filled${disableSubmit ? ' disabled' : ''}`}
                    onClick={onClickSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </>
          }

          {/* Message Modal */}
          <MessageModal modalDetails={modals.messageModal}/>

          {/* Confirmation Modal */}
          <ConfirmationModal modalDetails={modals.confirmationModal}/>

          {/* Cropping Modal */}
          <CroppingModal modalDetails={modals.croppingModal}/>
        </AppContext.Provider>
      }
    </div>
  );
}

export default App;
