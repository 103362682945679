import axios from 'axios';

function getBaseUrl() {
  // get basic url for endpoints
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  return 'https://dev.iapi.uworkapp.com';
  }
  return 'https://iapi.uworkapp.com';
}

axios.defaults.baseURL = getBaseUrl();

function getConfig() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Platform-Name': 'web-front',
    },
  };
}

function getFileUploadConfig() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Platform-Name': 'web-front',
    },
  };
}

export function getAppDownloadLinks() {
  return axios.get('/static/app_download_links', getConfig());
}

export function getTicketLinks() {
  return axios.get('/static/tickets_links', getConfig());
}

export function getUploadTypes() {
  return axios.get('/static/upload_types', getConfig());
}

export function checkAlreadyUploaded(id) {
  return axios.get(`/tickets/${id}/check`, getConfig());
}

export function uploadTicket(id, data) {
  return axios.post(`/tickets/${id}/add`, data, getFileUploadConfig());
}

export function removeTicket(id, path) {
  return axios.post(`/tickets/${id}/delete`, {path: path}, getConfig());
}

export function submitTickets(id) {
  return axios.post(`/tickets/${id}/submit`, null, getConfig());
}
